import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  useLazyGetAwardeeByIdQuery,
  useLazyGetAwardeGraphByIdQuery,
} from "../../services/rtkQueryServices/checkApiService";
import Awardee from "../Awardee/Awardees";
import Slider from "../Shared/Widgets/Slider/Slider";
import NAM from "../../assets/images/NAM.png";
import NAE from "../../assets/images/NAE.png";
import AAS from "../../assets/images/AAS.png";
import NAS from "../../assets/images/NAS.jpg";
import NAI from "../../assets/images/NAI.jpeg";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import "./Award.scss";
import AwardsFilters from "../AwardsFilters/AwardsFilters";
import Awards from "../Awards/awards";
import RecentAwardees from "../RecentAwardees/recentAwardees";
import { BarChart } from "@mui/x-charts/BarChart";

const Award = () => {
  const location = useLocation();
  const awardees = location.state?.awardData?.data || [];
  const currentAwardData = location.state?.currentAwardData || {};
  const [openSlider, setOpenSlider] = useState(false);
  const [graphInputData, setGraphInputData] = useState([]);
  const [setAwardeeData, { data: awardeeData }] = useLazyGetAwardeeByIdQuery();
  const [setAwardGraphData, { data: awardGraphData }] =
    useLazyGetAwardeGraphByIdQuery();

  const [sliderTitle, setSliderTitle] = useState("");
  useEffect(() => {
    if (openSlider) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    };
  }, [openSlider]);
  useEffect(() => {
    if (awardees.length > 200 && currentAwardData?.id) {
      setAwardGraphData(currentAwardData?.id);
    }
    clearFilter();
  }, [awardees]);

  useEffect(() => {
    let dummy = [];
    awardGraphData?.data?.forEach((data) => {
      for (let i = 1981; i < 2024; i++) {
        const maleValue = data[`gcm_${i}`] || 0;
        const femaleValue = data[`gcw_${i}`] || 0;
        const total = maleValue + femaleValue;

        dummy.push({
          Male: total > 0 ? (maleValue / total) * 100 : 0,
          Female: total > 0 ? (femaleValue / total) * 100 : 0,
          year: i,
        });
      }
    });

    setGraphInputData(dummy);
  }, [awardGraphData, awardGraphData?.data]);

  const handleRowclicked = (rowData) => {
    rowData.RaiseProjectID && setAwardeeData(rowData?.RaiseProjectID);
    setSliderTitle(rowData?.FullName);
    setOpenSlider(true);
  };

  const awardeesByYear = awardees.reduce((acc, awardee) => {
    const {
      Year_of_Award,
      FullName,
      uta,
      NAM: isNAM,
      AAS: isAAS,
      NAS: isNAS,
      NAE: isNAE,
      NAI: isNAI,
    } = awardee;
    if (!acc[Year_of_Award]) {
      acc[Year_of_Award] = [];
    }
    acc[Year_of_Award].push({
      FullName,
      uta,
      isNAM,
      isAAS,
      isNAS,
      isNAE,
      isNAI,
      awardee,
    });
    return acc;
  }, {});

  const sortedYears = Object.keys(awardeesByYear).sort((a, b) => b - a);

  const handleOnCloseClick = () => {
    setOpenSlider(false);
    document.body.style.overflow = "auto";
  };

  const [localFilter, setLocalFilter] = useState({
    awardName: "",
    organization: "",
    firstName: "",
    lastName: "",
  });

  const [filterCriteria, setFilterCriteria] = useState({
    pageNumber: 1,
    pageSize: 20,
  });

  const clearFilter = () => {
    setLocalFilter({
      awardName: "",
      organization: "",
      firstName: "",
      lastName: "",
    });
    setFilterCriteria({
      pageNumber: 1,
      pageSize: 20,
    });
  };

  const applyFilter = (localFilterValues) => {
    setFilterCriteria((prev) => ({
      ...prev,
      pageNumber: 1,
      awardName: localFilterValues.awardName,
      organization: localFilterValues.organization,
      firstName: localFilterValues.firstName,
      lastName: localFilterValues.lastName,
    }));
  };

  function valueFormatter(value) {
    return `${value.toFixed(2)}%`;
  }

  const chartSetting = {
    yAxis: [
      {
        label: "Percentage (%)",
        min: 0,
        max: 100,
      },
    ],
    xAxis: [
      {
        label: "Year",
        scaleType: "band",
        dataKey: "year",
        tickPlacement: "middle",
      },
    ],
    width: 700,
    maxWidth: "100%",
    height: 250,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-6px, 0)",
      },
    },
  };

  return (
    <div className="bg-white p-3">
      <div className="bg-white mb-3 py-3 awards-filter-wrapper">
        <AwardsFilters
          setFilterCriteria={setLocalFilter}
          applyFilter={applyFilter}
          clearFilter={clearFilter}
          isAwardsPage={true}
          isHomePage={true}
        />
      </div>
      {(filterCriteria?.lastName === "" ||
        filterCriteria?.lastName === undefined) &&
      filterCriteria?.awardName ? (
        <Awards filterCriteria={filterCriteria} isHomePage={true} />
      ) : filterCriteria?.lastName ? (
        <RecentAwardees filterCriteria={filterCriteria} isHomepage={true} />
      ) : awardees?.length > 0 ? (
        <div className="d-flex">
          <div className="award-left-wrapper me-5">
            <div className="mb-3">
              <span className="font-weight-bold">Award :</span>{" "}
              <a
                href={`${
                  currentAwardData?.website?.startsWith("https://")
                    ? currentAwardData.website
                    : `https://${currentAwardData?.website}`
                }`}
                target="_blank"
                rel="noopener noreferrer"
                className="award-name"
              >
                {currentAwardData.awardName || ""}
              </a>
            </div>
            <div className="mb-3">
              <span className="font-weight-bold">Society:</span>{" "}
              {awardees[0].Society}
            </div>
            <div className="mb-3">
              <span className="font-weight-bold">Kind :</span>{" "}
              {currentAwardData.awardkind || "Recognition"}
            </div>
            <p>{currentAwardData?.awardNotes}</p>
            <div className="my-3">
              <span className="nam-wrapper me-2">
                <img
                  title={"National Academy of Sciences"}
                  src={NAS}
                  alt="NAS"
                />
              </span>
              Indicates elected membership in National Academy of Sciences.
            </div>
            <div className="my-3">
              <span className="nam-wrapper me-2">
                <img
                  title={"National Academy of Engineering"}
                  src={NAE}
                  alt="NAE"
                />
              </span>
              Indicates elected membership in National Academy of Engineering.
            </div>
            <div className="my-3">
              <span className="nam-wrapper me-2">
                <img
                  title={"National Academy of Medicine"}
                  src={NAM}
                  alt="NAM"
                />
              </span>
              Indicates elected membership in National Academy of Medicine.
            </div>
            <div className="my-3">
              <span className="nam-wrapper me-2">
                <img
                  title={"National Academy of Inventors"}
                  src={NAI}
                  alt="NAI"
                />
              </span>
              Indicates elected membership in National Academy of Inventors.
            </div>
            <div className="my-3">
              <span className="nam-wrapper me-2">
                <img
                  title={"American Association of Arts and Sciences"}
                  src={AAS}
                  alt="AAS"
                />
              </span>
              Indicates elected membership in American Association of Arts and
              Sciences.
            </div>
            <div className="my-3 uta-faculty-note">
              Note : The highlighted individual is a faculty member at UTA
              (University of Texas at Arlington).
            </div>
          </div>
          <div className="award-right-wrapper d-flex align-items-center flex-column">
            <>
              {currentAwardData.percentageWomen &&
                currentAwardData.firstYearRecorded && (
                  <div className="analysis-wrapper mb-3 w-100">
                    {"Since " +
                      currentAwardData.firstYearRecorded +
                      ", " +
                      (currentAwardData.percentageWomen +
                        " % of the awardees are women.")}
                  </div>
                )}
              <div className="total-awardees-count-wrapper w-100  mb-3">
                Total awardees since 1981 = {awardees.length}
              </div>
              {awardees.length <= 200 ? (
                <>
                  <div className="d-flex flex-wrap justify-content-center">
                    {sortedYears.map((year) => (
                      <div
                        className="awardee-card-wrapper w-100 d-flex m-1"
                        key={year}
                      >
                        <div className="me-2">{year}:</div>
                        <div>
                          {awardeesByYear[year].map((awardeeInfo, index) => (
                            <span
                              key={index}
                              className="awardee-fullname-wrapper ms-1 cursor-pointer"
                              onClick={() => {
                                handleRowclicked(awardeeInfo.awardee);
                              }}
                            >
                              <span
                                className={
                                  awardeeInfo.uta === "checked"
                                    ? "awardee-fullname"
                                    : ""
                                }
                              >
                                {awardeeInfo.FullName}
                              </span>
                              {awardeeInfo.isAAS === "a" && (
                                <img
                                  className="ms-2"
                                  title={
                                    "American Association of Arts and Sciences"
                                  }
                                  src={AAS}
                                  alt="AAS"
                                />
                              )}
                              {awardeeInfo.isNAM === "#" && (
                                <img
                                  className="ms-2"
                                  title={"National Academy of Medicine"}
                                  src={NAM}
                                  alt="NAM"
                                />
                              )}
                              {awardeeInfo.isNAS === "!" && (
                                <img
                                  className="ms-2"
                                  title={"National Academy of Sciences"}
                                  src={NAS}
                                  alt="NAS"
                                />
                              )}
                              {awardeeInfo.isNAE === "*" && (
                                <img
                                  className="ms-2"
                                  title={"National Academy of Engineering"}
                                  src={NAE}
                                  alt="NAE"
                                />
                              )}
                              {awardeeInfo.isNAI === "^" && (
                                <img
                                  className="ms-2"
                                  title={"National Academy of Inventors"}
                                  src={NAI}
                                  alt="NAI"
                                />
                              )}
                              {index < awardeesByYear[year].length - 1
                                ? ", "
                                : ""}
                            </span>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  <Slider isOpen={openSlider} onClose={handleOnCloseClick}>
                    <Awardee
                      awardeeData={awardeeData}
                      handleOnCloseClick={handleOnCloseClick}
                      sliderTitle={sliderTitle}
                    />
                  </Slider>
                </>
              ) : (
                <>
                  <div className="align-items-center d-flex flex-column text-align-center">
                    <div>
                      {
                        "There are >200 winners - go to the awards website for names"
                      }
                    </div>
                    <a
                      href={`${
                        currentAwardData?.website?.startsWith("https://")
                          ? currentAwardData.website
                          : `https://${currentAwardData?.website}`
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="award-name"
                    >
                      {currentAwardData.awardName || ""}
                    </a>
                  </div>
                  <BarChart
                    dataset={graphInputData}
                    xAxis={[{ scaleType: "band", dataKey: "year" }]}
                    yAxis={[
                      {
                        dataKey: "percentage",
                        label: "Percentage (%)",
                        min: 0,
                        max: 100,
                      },
                    ]}
                    series={[
                      {
                        dataKey: "Male",
                        label: "Male",
                        stack: true,
                        color: "#2e96ff",
                        valueFormatter,
                      },
                      {
                        dataKey: "Female",
                        label: "Female",
                        stack: true,
                        color: "red",
                        valueFormatter,
                      },
                    ]}
                    {...chartSetting}
                  />
                </>
              )}
            </>
          </div>
        </div>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default Award;
