import {
  useGetUtaFacultyMasterDataQuery,
  useLazyGetAwardeeByIdQuery,
  useLazyGetUTAFacultyByIdQuery,
} from "../../services/rtkQueryServices/checkApiService";
import "./UTAFacultyAwards.scss";
import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Slider from "../Shared/Widgets/Slider/Slider";
import Awardee from "../Awardee/Awardees";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const UTAFacultyAwards = () => {
  const { data: utaFacultyMasterData, error } =
    useGetUtaFacultyMasterDataQuery();
  const [setFacultyData, { data: facultyData }] =
    useLazyGetUTAFacultyByIdQuery();
  const [prestigiousAwards, setPrestigiousAwards] = useState([]);
  const [universityAwards, setUniversityAwards] = useState([]);
  const [utaFacultyAwardHeader, setUtaFacultyAwardHeader] = useState();
  const [openSlider, setOpenSlider] = useState(false);
  const [sliderTitle, setSliderTitle] = useState("");
  const [setAwardeeData, { data: awardeeData }] = useLazyGetAwardeeByIdQuery();

  useEffect(() => {
    if (openSlider) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    };
  }, [openSlider]);

  useEffect(() => {
    let a1 = [];
    let a2 = [];
    utaFacultyMasterData?.data?.map((item) => {
      if (item.isPrestigiousAward === 1) a1.push(item);
      else a2.push(item);
    });
    setPrestigiousAwards(a1);
    setUniversityAwards(a2);
    if (a1.length > 0) {
      setFacultyData(a1[0].pk_award);
      setUtaFacultyAwardHeader(a1[0].Award);
    }
  }, [utaFacultyMasterData]);

  const handleAwardNameClick = (pk_award, awardName) => {
    pk_award && setFacultyData(pk_award);
    setUtaFacultyAwardHeader(awardName);
  };

  const handleAwardeeClick = (data) => {
    data.RaiseProjectID && setAwardeeData(data?.RaiseProjectID);
    setSliderTitle(data?.FullName);
    setOpenSlider(true);
  };

  const handleOnCloseClick = () => {
    setOpenSlider(false);
    document.body.style.overflow = "auto";
  };

  if (error) {
    return <Typography variant="h6">Error: {error.message}</Typography>;
  }

  return (
    <div className="bg-white p-3 uta-faculty-awards-container">
      <div>
        <h2 className="awards-and-honors">Awards and Honors</h2>
        <div className="d-flex">
          <div className="left-wrapper me-5">
            <div className="mb-4">
              <div className="uta-faculty-side-header-wrapper">
                SELECTED PRESTIGIOIUS AWARDS
              </div>
              {prestigiousAwards.map((item, index) => (
                <>
                  <div
                    onClick={() => {
                      handleAwardNameClick(item?.pk_award, item.Award);
                    }}
                    className={
                      item.Award === utaFacultyAwardHeader
                        ? "cursor-pointer uta-faculty-award-wrapper active"
                        : "cursor-pointer uta-faculty-award-wrapper"
                    }
                  >
                    {item.Award}
                  </div>
                </>
              ))}
            </div>
            <div>
              <div className="uta-faculty-side-header-wrapper">
                SELECTED UNIVERSITY AWARDS
              </div>
              {universityAwards.map((item, index) => (
                <>
                  <div
                    onClick={() => {
                      handleAwardNameClick(item?.pk_award, item.Award);
                    }}
                    className={
                      item.Award === utaFacultyAwardHeader
                        ? "cursor-pointer uta-faculty-award-wrapper active"
                        : "cursor-pointer uta-faculty-award-wrapper"
                    }
                  >
                    {item.Award}
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="right-wrapper flex-grow-1 px-4">
            <div className="uta-faculty-award-header-wrapper">
              {utaFacultyAwardHeader}
            </div>
            {facultyData?.data?.map((item, index) => (
              <div className="faculty-data-wrapper my-3">
                <div className="d-flex my-3">
                  <div className="me-5">
                    {item.faculty_profile_link ? (
                      <img
                        className="profile-img"
                        src={item.faculty_profile_link}
                      />
                    ) : (
                      <img
                        className="profile-img"
                        src={
                          "https://www.digitalmeasures.com/services/self-service-reporting/profiles/images/default"
                        }
                      />
                    )}
                  </div>
                  <div>
                    <h4 className="font-weight-bold">{item.FullName}</h4>
                    <div>{item.Department}</div>
                    <div>{"Year of Award :" + item.Year_of_Award}</div>
                    <div className="d-flex justify-content-end">
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          handleAwardeeClick(item);
                        }}
                      >
                        <ArrowForwardIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Slider isOpen={openSlider} onClose={handleOnCloseClick}>
            <Awardee
              awardeeData={awardeeData}
              handleOnCloseClick={handleOnCloseClick}
              sliderTitle={sliderTitle}
            />
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default UTAFacultyAwards;
