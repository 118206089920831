import React, { useEffect, useState } from "react";
import { TextField, Grid, Button } from "@mui/material";
import { useLazyGetAwardsBySearchStringQuery } from "../../services/rtkQueryServices/checkApiService";
import ComboBox from "../Shared/Widgets/Autocomplete/Autocomplete";

const AwardsFilters = ({
  setFilterCriteria,
  applyFilter,
  clearFilter,
  isAwardsPage,
  isHomePage,
}) => {
  const [triggerFetchAwards, { data: awardsData, isSuccess }] =
    useLazyGetAwardsBySearchStringQuery();
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [localFilter, setLocalFilter] = useState({
    awardName: "",
    organization: "",
    firstName: "",
    lastName: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLocalFilter({
      ...localFilter,
      [name]: value,
    });
  };

  const handleApplyFilter = () => {
    applyFilter(localFilter);
  };

  const handleClearFilter = () => {
    setLocalFilter({
      awardName: "",
      organization: "",
      firstName: "",
      lastName: "",
    });
    clearFilter();
  };

  const fetchAwards = async (query) => {
    try {
      triggerFetchAwards(query);
    } catch (error) {
      console.error("Error triggering awards fetch:", error);
    }
  };

  return (
    <Grid container spacing={2}>
      {(isAwardsPage || isHomePage) && (
        <Grid item xs={12} sm={3}>
          <TextField
            label="Enter an Award"
            name="awardName"
            value={localFilter.awardName}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
      )}

      {
        // !isAwardsPage &&
        <>
          {/* <Grid item xs={12} sm={3}>
            <TextField
              label="Organization"
              name="organization"
              value={localFilter.organization}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={3}>
            <TextField
              label="First Name"
              name="firstName"
              value={localFilter.firstName}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid> */}
        </>
      }
      {(isHomePage || !isAwardsPage) && (
        <Grid item xs={12} sm={3}>
          <TextField
            label="Enter Last Name"
            name="lastName"
            value={localFilter.lastName}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
      )}

      {
        // isAwardsPage &&
        <>
          <Grid item xs={12} sm={3}>
            <div className="h-100 d-flex align-items-center justify-content-end">
              <Button
                className="me-3"
                variant="contained"
                color="primary"
                onClick={handleApplyFilter}
              >
                Search
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className="h-100 d-flex align-items-center">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClearFilter}
              >
                Clear Filter
              </Button>
            </div>
          </Grid>
        </>
      }
      {/* {!isAwardsPage && (
        <div className="d-flex w-100 justify-content-end my-3">
          <Button
            className="me-3"
            variant="contained"
            color="primary"
            onClick={handleApplyFilter}
          >
            Search
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClearFilter}
          >
            Clear Filter
          </Button>
        </div>
      )} */}
    </Grid>
  );
};

export default AwardsFilters;
